<template>
  <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.67993C1 2.57536 1.89543 1.67993 3 1.67993H23C24.1046 1.67993 25 2.57536 25 3.67993V15.9999C25 17.1045 24.1046 17.9999 23 17.9999H3C1.89543 17.9999 1 17.1045 1 15.9999V3.67993Z"
      stroke="#363636"
      stroke-width="1"
    />
    <path
      d="M15.6 11.2402C15.21 11.7002 14.66 11.9702 14.04 11.9702C12.92 11.9702 11.78 11.1702 11.78 9.69024C11.78 8.46024 12.65 7.36024 13.99 7.36024C14.23 7.36024 14.99 7.41024 15.6 8.11024V6.59024C14.99 6.26024 14.43 6.18024 13.96 6.18024C13.01 6.18024 12.18 6.43024 11.51 7.06024C10.94 7.59024 10.44 8.46024 10.44 9.67024C10.44 10.6502 10.74 11.4802 11.51 12.2202C12.07 12.7502 12.78 13.1502 13.95 13.1502C14.67 13.1502 15.2 12.9702 15.6 12.7602V11.2402Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheMemberCardIcon',
};
</script>
