












import { Component, Prop, Vue } from 'vue-property-decorator';

import DownloadMobileAppModal from '@/components/modals/DownloadMobileAppModal.vue';
import TheMemberCardIcon from '@/components/icons/TheMemberCardIcon.vue';

@Component({
  components: {
    DownloadMobileAppModal,
    TheMemberCardIcon,
  },
})
export default class UseCultivistCardAction extends Vue {
  @Prop() labelClass: string;
  @Prop({ default: 'data-details__action' }) wrapperClass: string;
  @Prop({ default: false, type: Boolean }) showAccessInstructions: boolean;
  @Prop({ default: false, type: Boolean }) isMobile: boolean;

  showModal = false;

  open() {
    this.showModal = true;
    this.$emit('toggle', this.showModal);
  }

  close() {
    this.showModal = false;
    this.$emit('toggle', this.showModal);
  }
}
